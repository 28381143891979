// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate

// const Login = ({ onLogin }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate(); // Initialize useNavigate

//   const handleLogin = (e) => {
//     e.preventDefault();
//     // Simulate authentication and call onLogin prop to set user data
//     onLogin({ email });
//     // After successful login, redirect to dashboard
//     navigate('/dashboard');
//   };

//   return (
//     <div style={styles.container}>
//       <form style={styles.form} onSubmit={handleLogin}>
//         <h2>Login</h2>
//         <div style={styles.inputContainer}>
//           <input
//             type="email"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//             style={styles.input}
//           />
//         </div>
//         <div style={styles.inputContainer}>
//           <input
//             type="password"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//             style={styles.input}
//           />
//         </div>
//         <button type="submit" style={styles.button}>Login</button>

//         <p style={styles.link}>
//           <button onClick={() => alert('Forgot Password functionality coming soon!')} style={styles.linkButton}>
//             Forgot Password?
//           </button>
//         </p>
        
//         <p style={styles.link}>
//           Don't have an account?{' '}
//           <button onClick={() => alert('Sign Up functionality coming soon!')} style={styles.linkButton}>
//             Sign Up
//           </button>
//         </p>
//       </form>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundColor: '#f7f7f7',
//   },
//   form: {
//     padding: '30px',
//     backgroundColor: '#fff',
//     borderRadius: '8px',
//     boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//     width: '300px',
//   },
//   inputContainer: {
//     marginBottom: '15px',
//   },
//   input: {
//     width: '100%',
//     padding: '10px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   },
//   button: {
//     width: '100%',
//     padding: '10px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     borderRadius: '5px',
//     border: 'none',
//     cursor: 'pointer',
//   },
//   link: {
//     marginTop: '10px',
//     fontSize: '14px',
//   },
//   linkButton: {
//     background: 'none',
//     border: 'none',
//     color: '#007bff',
//     cursor: 'pointer',
//     textDecoration: 'underline',
//     padding: '0',
//   },
// };

// export default Login;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider, signInWithEmailAndPassword, signInWithRedirect } from './firebase'; // Import auth and googleProvider

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Firebase email/password sign-in
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      onLogin({ email: user.email });
      navigate('/dashboard'); // Redirect to dashboard after login
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      // Firebase Google sign-in
      const result = await signInWithRedirect(auth, googleProvider);
      console.log('Sign-in result:', result); // Log sign-in result

      // Get user info
      const user = result.user;
      console.log('User logged in:', user);

      // This gives you a Google Access Token. You can use it to access Google APIs if needed.
      const credential = googleProvider.credentialFromResult(result);
      const token = credential.accessToken;
      console.log('Access Token:', token);

      // Optionally: Log the access token and user information if needed
      console.log('Google Access Token:', token);
      console.log('Logged in user:', user);

      // Call the onLogin function to update the app's state
      onLogin({ email: user.email });

      // Redirect to dashboard after login
      navigate('/dashboard');
    } catch (err) {
      console.error('Error during Google login:', err);

      // Handle Errors
      const errorCode = err.code;
      const errorMessage = err.message;
      const email = err.customData?.email; // The email of the user's account used, if available

      console.error('Error Code:', errorCode);
      console.error('Error Message:', errorMessage);
      console.error('User Email (if available):', email);

      setError(errorMessage); // Display the error message in your app's UI
    }
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleLogin}>
        <h2>Login</h2>
        {error && <p style={styles.error}>{error}</p>} {/* Display errors */}
        <div style={styles.inputContainer}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.inputContainer}>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.button}>Login</button>
        <button type="button" style={styles.googleButton} onClick={handleGoogleLogin}>
          Sign in with Google
        </button>

        <p style={styles.link}>
          <button onClick={() => alert('Forgot Password functionality coming soon!')} style={styles.linkButton}>
            Forgot Password?
          </button>
        </p>
        
        <p style={styles.link}>
          Don't have an account?{' '}
          <button onClick={() => alert('Sign Up functionality coming soon!')} style={styles.linkButton}>
            Sign Up
          </button>
        </p>
      </form>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f7f7f7',
  },
  form: {
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    width: '300px',
  },
  inputContainer: {
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  googleButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#db4437',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    marginTop: '10px',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
  link: {
    marginTop: '10px',
    fontSize: '14px',
  },
  linkButton: {
    background: 'none',
    border: 'none',
    color: '#007bff',
    cursor: 'pointer',
    textDecoration: 'underline',
    padding: '0',
  },
};

export default Login;

